footer{
	width: 100%;
	height: auto;
	background-color: #bcbcbc;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	padding: 15px;
	article{
		font-family: $fontRoboto;
		color: #fff;
		text-align: center;
		a{
			font-family: $fontRoboto;
			font-weight: 500;
			color: #fff;
		}
	}
}