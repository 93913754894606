@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
* {
  padding: 0;
  margin: 0;
  font-family: "Cormorant Garamond", serif;
  font-weight: 300;
  color: #333;
  text-decoration: none;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

body, html {
  height: 100%;
}

.appContainer {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
}

section {
  width: 100%;
  max-width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

section {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
section .bkg {
  width: 100%;
  height: 600px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  order: 2;
}
section .contacto {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 50px;
  order: 1;
}
section .contacto .container-info {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #333;
}
section .contacto .container-info article {
  width: 100%;
  padding: 30px 10px;
  font-size: 18px;
  font-weight: 900;
}
section .contacto .container-info img {
  max-width: 300px;
}
section .contacto .container-bajada {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
section .contacto .container-bajada article {
  width: 100%;
  padding: 30px 10px 0px 10px;
  font-size: 18px;
  font-weight: 900;
  text-align: left;
}
section .contacto h2 {
  width: 100%;
  font-size: 40px;
  line-height: 40px;
}
section .contacto h4 {
  width: 100%;
  font-size: 12px;
}
section .contacto .container-form {
  padding: 5px;
  display: flex;
  justify-content: center;
}
section .contacto .container-form form {
  width: 100%;
  margin-top: 20px;
}
section .contacto .container-form form article {
  width: 100%;
  padding: 10px 0px;
  font-size: 16px;
  font-weight: 900;
  text-align: left;
}
section .contacto .container-form form input, section .contacto .container-form form textarea {
  width: 100%;
  padding: 10px;
  border: none;
  margin: 5px 0px;
  font-size: 16px;
  background-color: #ededed;
  font-family: "Roboto", sans-serif;
}
section .contacto .container-form form textarea {
  min-width: 100%;
  max-width: 100%;
  min-height: 100px;
  max-height: 400px;
}
section .contacto .container-form form .btnN {
  width: auto;
  height: auto;
  padding: 10px 20px;
  background-color: #ababab;
  border: 2px solid #ababab;
  font-size: 18px;
  color: #fff;
}
section .contacto .container-form form .btnN:hover {
  background-color: #ededed;
  border: 2px solid #ededed;
  color: #333;
  transition: 0.6s;
  cursor: pointer;
}
section .contacto .container-img {
  width: 500px;
}
section .contacto .container-img img {
  max-width: 100%;
}

@media only screen and (min-width: 900px) {
  section .bkg {
    width: 50%;
    height: 1260px;
    order: 1;
  }
  section .contacto {
    width: 50%;
    order: 2;
  }
  section .contacto .container-info {
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #333;
  }
  section .contacto .container-info article {
    width: 75%;
    padding: 30px 10px;
    font-size: 18px;
    font-weight: 900;
  }
  section .contacto .container-info img {
    max-width: 400px;
  }
  section .contacto .container-form {
    padding: 20px;
    display: flex;
    justify-content: center;
  }
  section .contacto .container-form form {
    width: 80%;
    margin-top: 20px;
  }
  section .contacto .container-form form article {
    width: 100%;
    font-size: 18px;
    font-weight: 900;
    text-align: left;
  }
  section .contacto .container-form form input, section .contacto .container-form form textarea {
    width: 100%;
    padding: 10px;
    border: none;
    margin: 5px 0px;
    font-size: 16px;
    background-color: #ededed;
    font-family: "Roboto", sans-serif;
  }
}
footer {
  width: 100%;
  height: auto;
  background-color: #bcbcbc;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
}
footer article {
  font-family: "Roboto", sans-serif;
  color: #fff;
  text-align: center;
}
footer article a {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #fff;
}/*# sourceMappingURL=styles.css.map */