@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

$colorMain: #333;
$colorSecondary: #999;
$colorWhite: #fff;
$colorGray: #eaeaea;

$fontCormorant: 'Cormorant Garamond', serif;
$fontRoboto: 'Roboto', sans-serif;

*{
	padding: 0;
	margin: 0;
	font-family: $fontCormorant;
	font-weight: 300;
	color: $colorMain;
	text-decoration: none;
	box-sizing: border-box;
	scroll-behavior: smooth;
}

body{
	width: 100%;
	height: 100%;
	background-color: $colorWhite;
}
body, html{
	height: 100%;
}

.appContainer{
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

main{
	flex-grow: 1;
}

section{
	width: 100%;
	max-width: 100vw;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	overflow: hidden;
}

@import 'components/ContactoForm/contacto-form';
@import 'components/Footer/footer';