section{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .bkg{
        width: 100%;
        height: 600px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        order: 2;
    }
    .contacto{
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 50px;
        order: 1;
        .container-info{
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #333;
            article{
                width: 100%;
                padding: 30px 10px;
                font-size: 18px;
                font-weight: 900;
            }
            img{
                max-width: 300px;
            }
        }
        .container-bajada{
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            article{
                width: 100%;
                padding: 30px 10px 0px 10px;
                font-size: 18px;
                font-weight: 900;
                text-align: left;
            }
        }
        h2{
            width: 100%;
            font-size: 40px;
            line-height: 40px;
        }
        h4{
            width: 100%;
            font-size: 12px;
        }
        .container-form{
            padding: 5px;
            display: flex;
            justify-content: center;
            form{
                width: 100%;
                margin-top: 20px;
                article{
                    width: 100%;
                    padding: 10px 0px;
                    font-size: 16px;
                    font-weight: 900;
                    text-align: left;
                }
                input, textarea{
                    width: 100%;
                    padding: 10px;
                    border: none;
                    margin: 5px 0px;
                    font-size: 16px;
                    background-color: #ededed;
                    font-family: $fontRoboto;
                }
                textarea{
                    min-width: 100%;
                    max-width: 100%;
                    min-height: 100px;
                    max-height: 400px;
                }
                .btnN{
                    width: auto;
                    height: auto;
                    padding: 10px 20px;
                    background-color: #ababab;
                    border: 2px solid #ababab;
                    font-size: 18px;
                    color: #fff;
                }
                .btnN:hover{
                    background-color: #ededed;
                    border: 2px solid #ededed;
                    color: #333;
                    transition: 0.6s;
                    cursor: pointer;
                }
            }
        }
        .container-img{
            width: 500px;
            img{
                max-width: 100%;
            }
        }
    }
}

@media only screen and (min-width: 900px){
    section{
        .bkg{
            width: 50%;
            height: 1260px;
            order: 1;
        }
        .contacto{
            width: 50%;
            order: 2;
            .container-info{
                width: 90%;
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-bottom: 1px solid #333;
                article{
                    width: 75%;
                    padding: 30px 10px;
                    font-size: 18px;
                    font-weight: 900;
                }
                img{
                    max-width: 400px;
                }
            }
            .container-form{
                padding: 20px;
                display: flex;
                justify-content: center;
                form{
                    width: 80%;
                    margin-top: 20px;
                    article{
                        width: 100%;
                        font-size: 18px;
                        font-weight: 900;
                        text-align: left;
                    }
                    input, textarea{
                        width: 100%;
                        padding: 10px;
                        border: none;
                        margin: 5px 0px;
                        font-size: 16px;
                        background-color: #ededed;
                        font-family: $fontRoboto;
                    }
                }
            }
        }
    }
}